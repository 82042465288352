<template>
    <div >
        <!--篩選區塊-->
        <el-row>
         <el-button
         style="float: left; margin: 7px"
         size="mini"
         type="primary"
         icon="el-icon-plus"
         @click="handleAdd('add')"
       >
         新增
       </el-button>
    </el-row>
    <el-divider content-position="right"></el-divider>
     <el-row>
           <el-table
             :data="alertInfo_Table" style="width: 100%;" 
             :cell-style="{padding: '0', height: '70px'}"
              @sort-change="tableSortChange"
           >
           <el-table-column  align="center" label="使用者名稱"   :render-header="renderNameHeaderMethods" :show-overflow-tooltip="true" >
           <template slot-scope="scope">
            <el-tooltip v-if="scope.row.userName != null" class="item" effect="dark" :content="scope.row.userName" placement="top-start">
            <span v-if="scope.row.userName != null">{{hideName(scope.row.userName)}}</span>
            </el-tooltip>
          </template>
        </el-table-column>

           <el-table-column  align="center"  label="使用者帳號" :render-header="renderEmailHeaderMethods"  >
            <template slot-scope="scope">
            <el-tooltip v-if="scope.row.userId != null" class="item" effect="dark" :content="scope.row.userId" placement="top-start">
              <span v-if="scope.row.userId != null">{{hideEmail(scope.row.userId)}}</span>
            </el-tooltip>
          </template>
          </el-table-column>
           <el-table-column  align="center"  label="email"  :render-header="renderEmailHeaderMethods"  >
           <template slot-scope="scope">
            <el-tooltip v-if="scope.row.email != null" class="item" effect="dark" :content="scope.row.email" placement="top-start">
              <span >{{hideEmail(scope.row.email)}}</span>
            </el-tooltip>
          </template>
          </el-table-column>
           <el-table-column  align="center" label="電話號碼"  :render-header="renderAccountHeaderMethods" :show-overflow-tooltip="true" >
           <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.phone" placement="top-start">
              <span>{{scope.row.phone.replace(scope.row.phone.substr(6,4),"****")}}</span>
            </el-tooltip>
          </template>
          </el-table-column>
           <el-table-column  align="center" prop="line" label="Line(token)"  :show-overflow-tooltip="true" />
           <el-table-column  align="center" prop="login" label="登入帳號" :render-header="renderNameHeaderMethods" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.login" placement="top-start">
              <span>{{hideName(scope.row.login)}}</span>
            </el-tooltip>
          </template>
          </el-table-column>
           <el-table-column  align="center" prop="createOn" label="建立時間"  :show-overflow-tooltip="true" />
           <el-table-column  align="center" label="操作">
            <template slot-scope="scope">
                 <el-button  type="success" size="mini" icon="el-icon-setting"  @click="handleAdd('update',scope.row)"/>
                 <el-button  type="danger" size="mini" icon="el-icon-delete"  @click="deleteAlertUser(scope.row)"/>
             </template>
           </el-table-column>
           </el-table>
       </el-row>

        <!-- 編輯 -->
    <el-dialog width="600px"  :visible.sync="dialogSettingVisible" @close="dialogOnClose()">
       <span class="filter-label" style="font: 2em sans-serif;">新增</span>
       <el-divider content-position="right"></el-divider>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">使用者帳號: </span>
            <el-select v-model="userAccount" :disabled="userOptions.length==0 || this.isfromNew==false" filterable class="filter-item-long" placeholder="" size="small" clearable @change="changeSelect(val)">
            <el-option v-for="item in userOptions" :key="item.accountId" :label="item.userName" :value="item.accountId"></el-option>
          </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label"> 電子郵件:</span>
            <el-input  v-model="inputEmail" size="small"  class="filter-item-long"  clearable />
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label"> 電話號碼:</span>
            <el-input v-model="inputTel" size="small"  class="filter-item-long"  clearable />
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label"> Line(token):</span>
            <el-input v-model="inputLine" size="small"  class="filter-item-long"  clearable />
            <el-button size="mini" @click="dialogLineToken = true" type="success" round>
             取得說明
            </el-button>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
            <span class="data-label">登入帳號:</span>
            <el-input v-model=this.loginAccount   size="small"  class="filter-item-long"  disable />
          </el-col>

      </el-row>
        <br>
        <br>
       <div style="text-align: center">
        <el-button size="small" @click="dialogSettingVisible = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="updateData()">
          確定
        </el-button>
      </div>

    </el-dialog>


    <el-dialog width="800px"  :visible.sync="dialogLineToken" @close="dialogOnClose()">
       <span class="filter-label" style="font: 2em sans-serif;">Line Token 取得說明</span>
       <el-divider content-position="right"></el-divider>

        <el-row :gutter="5">
         
            <span class="data-linetoken">1. 申請網址：https://notify-bot.line.me/zh_TW/ </span>
            <br>
            <br>
            <span class="data-linetoken">2. 點選右上角'登入'，並填入 LINE 帳號及密碼</span>
            <br>
            <br>
            <span class="data-linetoken">3. 點選右上角'個人頁面'</span>
            <br>
            <br>
           <template>
            <img src="../../assets/images/line1.png" width="500px" style="display: inline; vertical-align: middle; margin: 10px;" />
           </template>
           <span class="data-linetoken">4. 點選'發行權杖'</span>
            <br>
            <br>
           <template>
            <img src="../../assets/images/line2.png" width="400px" style="display: inline; vertical-align: middle; margin: 10px;" />
           </template>
            <br>
            <br>
            <span class="data-linetoken">5. 填寫'權杖名稱'並選擇要接收通知的聊天室，再按'發行'</span>
            <br>
            <br>
            <span class="data-linetoken">6. 取得 LINE 權杖，複製並填入 充電樁 告警發送對象之 LINE Notify 存取權杖欄位</span>
            <template>
            <img src="../../assets/images/line3.png" width="400px" style="display: inline; vertical-align: middle; margin: 10px;" />
           </template>
      </el-row>
        <br>
        <br>
       <div style="text-align: center">
       
      </div>

    </el-dialog>

    </div>
    </template>

       <script>
       //import Pagination from '@/components/Pagination'
       import { mixins } from '@/views/common/mixins.js'
       import {refreshToken,stopRefreshToken} from "@/utils/auth";

       export default {
           name: 'Setting',
           components: {
               //Pagination
           },
           mixins: [mixins],
           data() {
               return {
                alertInfo_Table:[],
                userAccount:undefined,
                dialogSettingVisible:false,
                userOptions:[],
                inputEmail:'',
                inputTel:'',
                inputLine:'',
                inputAccount:'',
                loginAccount:undefined,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                isfromNew: true,
                accountId:undefined,
                dialogLineToken:false,

               };
           },
         watch: {

         },
         created() {

           //this.dialogWidth = this.setDialogWidth()
           // await this.$store.dispatch("getUnitTable")
          // this.resetPage(this.getList)
          this.getNotifyList()
          this.loginAccount=this.$store.state.currentAccount.name
          //refreshToken();

         },
         beforeDestroy() {
         },

         mounted() {
           /*window.onresize = () => {
             return (() => {
               this.dialogWidth = this.setDialogWidth()
             })()
           }*/
           this.getNotifyList()
         },
         methods: {
           //取得
           getUserIDList(){
            this.targetUrl =  "/api/v1/notify/useridList?serviceId="+this.serviceId
            this.axios.get(this.targetUrl).then(res => {
              this.userOptions=res.data
    
          })
          },
           //告警列表
          getNotifyList(){
            this.targetUrl =  "/api/v1/notify/notifyInfo?serviceId="+this.serviceId
            this.axios.get(this.targetUrl).then(res => {
              this.alertInfo_Table=res.data.content
             
          })
          },

          handleAdd(val1,val2) {
             
             this.dialogSettingVisible = true
             this.getUserIDList()
             if(val1=='add')
             {
              this.userAccount=undefined
            this.inputEmail=undefined
            this.inputTel=undefined
            this.inputLine=undefined
           // this.loginAccount=undefined
             this.isfromNew=true
            }
             else
             {
            this.userAccount=val2.userName
            this.inputEmail=val2.email
            this.inputTel=val2.phone
            this.inputLine=val2.line
            this.loginAccount=val2.login
            this.isfromNew=false
            this.accountId=val2.accountId
          }
           },

           updateData(){   //新增參數, val表示 是更新還是新增, data表示從更新帶來的資料
         
            if(this.isfromNew==true)
           {
           if(this.inputEmail==undefined || this.inputEmail.length==0)
            return this.$message({ message: '請輸入email帳號', type: 'warning' })

           if(this.inputTel==null || this.inputTel.length==0)
            return this.$message({ message: '請輸入通知電話號碼', type: 'warning' }) 
          
           this.targetUrl = 
           "/api/v1/notify/addnotifyuser?serviceId="+this.serviceId+"&Id="+this.userAccount+"&email="+this.inputEmail+"&phone="+this.inputTel+"&line="+this.inputLine+"&loginuser="+this.loginAccount
           this.axios.get(this.targetUrl).then(res => {
          

           if(res.data.status=='ok')
           {
            this.getNotifyList();  //更新列表
            this.userAccount=undefined  //以下三行清空資料
            this.inputEmail=undefined
            this.inputTel=undefined
            this.inputLine=undefined
            this.getUserIDList();  //重新抓用戶列表
            this.dialogSettingVisible=false
            //this.userAccount.clearable;
           }

            })
          }
          else  //更新
          {
            //targetUrl= http://localhost:6783/api/v1/notify/updatenotifyuser?serviceId=1&Id=6&email=bb&phone=123&line=asb&loginuser=系統管理員
         
           this.targetUrl = "/api/v1/notify/updatenotifyuser?serviceId="+this.serviceId+"&Id="+this.accountId+"&email="+this.inputEmail+"&phone="+this.inputTel+"&line="+this.inputLine+"&loginuser="+this.loginAccount
           this.axios.get(this.targetUrl).then(res => {

           if(res.data.status=='ok')
           {
            this.getNotifyList();  //更新列表
            this.userAccount=undefined  //以下三行清空資料
            this.inputEmail=undefined
            this.inputTel=undefined
            this.inputLine=undefined
            this.getUserIDList();  //重新抓用戶列表
            this.dialogSettingVisible=false
            //this.userAccount.clearable;
           }

            })
          }


         },

         deleteAlertUser(val){
        
          // this.targetUrl = "/api/delnotifyuser?serviceId=1&userId="+this.userAccount
           this.targetUrl = "/api/v1/notify/delnotifyuser?serviceId="+this.serviceId+"&Id="+ val.accountId
        
           this.axios.get(this.targetUrl).then(res => {
        
           if(res.data.status=='ok')
           {
            this.getNotifyList();
             return this.$message({ message: '刪除成功', type: 'warning' })
           }
           else
              return this.$message({ message: '刪除失敗', type: 'warning' })

         })
         },
       changeSelect(val){
       
          //this.u
         },
         dialogOnClose() {
         this.userAccount=undefined

      this.$emit('close') // 關閉視窗

    },


      }
      }
       </script>

<style lang="scss" scoped>

.checkbox {
zoom:100%;
}
.alert {
zoom:100%;
}


.data-label {
  width: 120px; display: inline-block;
}

.data-linetoken {
  width: 600px; display: inline-block;
  font-size: 1.8em;
}

.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}
</style>
